.section-outline {
    border: solid 1px #e7e7e7;
    border-radius: 12px;
    padding: 5px;
}

.signature-canvas {
  width: calc(99.8%);
  height: calc(99.8%);
}

.signature-canvas-outline {
  border: solid 1px #b4b6bc;
  border-radius: 8px;
  padding: 5px;
  background: #fafafa;
  height: 170px;
}